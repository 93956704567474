import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { FormRecordResource, FormResource } from "../../Resources";
import { TitledContent } from "../layout/TitledContent"
import { Alert } from "../utils/alert";
import { Hide } from "../utils/hide";
import { PreviewRoot } from "./builder/index";

export const EFForm = () => {
    const [elems, setElems] = useState({ node: "div", class: "" });
    const [form, setForm] = useState({});
    const [inputs, setInputs] = useState({});
    const [alerts, setAlerts] = useState();
    const navigate = useNavigate();
    const form_id = useParams()["form_id"];

    useEffect(() => {
        FormResource.get(form_id, (r) => {
            if (r.status) {
                setElems(r.body.maped_data);
                setForm(r.body);
                return;
            }
            setAlerts(r.body);
        });
    }, [form_id]);

    const onChange = (e) => {
        setAlerts(null);
        const name = e.target.name;
        const value = e.target.value;
        switch (name) {
            case "document_required":
                setInputs(values => ({ ...values, [name]: e.target.checked }));
                break;

            default:
                setInputs(values => ({ ...values, [name]: value }));
                break;
        }
    }

    const onSubmit = (e) => {
        e.preventDefault();
        if (!e.target.checkValidity()) return;
        setAlerts(null);
        const formData = new FormData(e.target);
        for (const data of formData) {
            console.log(data[0], ":", data[1]);
        }
        formData.set("form_id", form_id)
        FormRecordResource.create(formData, (r) => {
            if (r.status) return navigate(`/electronic-forms/open/update/${r.body.id}`);
            setAlerts(r.body);
        });
    }

    const actions = () => {
        return [
            <Button onClick={() => navigate(`/electronic-forms/open/${form_id}`)} key="back" variant="secondary">Back</Button>
        ];
    }

    return (
        <TitledContent title="Electronic Forms" actions={actions()}>
            <div className="row">
                <form onSubmit={onSubmit}>
                    <div className="col-12 mb-2">
                        <div className="card shadow">
                            <div className="card-header py-3">
                                <p className="text-primary m-0 fw-bold">{form.title || ""}</p>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-12">
                                        <Alert alerts={alerts} tag="error" />
                                        <PreviewRoot root={elems} values={inputs} onChange={onChange} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Hide state={!form.document_required} >
                        <div className="col-12 mb-2">
                            <div className="card shadow mb-2">
                                <div className="card-header py-3">
                                    <p className="text-primary m-0 fw-bold">Documents</p>
                                </div>
                                <div className="card-body">
                                    <div className="text-center text-white w-100 p-3 bg-secondary mb-2"><i className="fas fa-plus fs-3"></i>
                                        <p>drag and drop files here or click to add</p>
                                    </div>
                                    <ul className="list-group">
                                        <li className="list-group-item"><span>File Name.pdf</span>
                                            <button className="btn btn-danger btn-sm float-end" type="button">
                                                <i className="fas fa-times"></i> remove
                                            </button>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </Hide>
                    <div className="col-12 my-3 text-end">
                        <button className="btn btn-primary" type="submit">
                            <i className="fas fa-file-alt fa-sm text-white"></i> Submit
                        </button>
                    </div>
                </form>
            </div>
        </TitledContent>);
}