import axios from "axios";
import { Config } from "./config";

export const CoyResource = {
    info(callback) {
        axios.post(`${Config.BaseUrl("manage")}/api/coyinfo`, { "sub_domain": Config.subDomain() })
            .then(resp => callback(resp.data))
            .catch(err => callback(err.response?.data));
    }
};

export default CoyResource