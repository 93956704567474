export const Alert = ({ alerts = {}, tag = "", type = "danger" }) => {
    if (alerts && alerts[tag]) {
        return <div role="alert" className={`alert alert-${type}`}><span>{alerts[tag]}</span></div>
    }
    return null;
}

export const InputAlert = ({ alerts = {}, tag = "", type = "danger" }) => {
    if (alerts && alerts[tag]) {
        return <div role="alert" className={`alert alert-${type} m-1`} ><span>{alerts[tag]}</span></div>
    }
    return null;
}