import { BrowserRouter, Route, Routes } from 'react-router-dom';
import "./fontawesome";
import { Home } from './components/Home';
import { lazy, Suspense } from 'react';
import { RequireAuth } from './components/utils/RequireAuth';
import { EFList, EFCreate, EFEdit, EFForm, EFOpen, EFFormUpdate, EFReview, EFFormReview } from './components/electronic-forms/index';
import Loading from './components/layout/loading';


const SignIn = lazy(() => import('./components/auth/login'));
const SignUp = lazy(() => import("./components/auth/signup"));
const Verify = lazy(() => import("./components/auth/verify"));
const PageContainer = lazy(() => import("./components/layout/container"));
const Profile = lazy(() => import('./components/user/profile'));

function App() {
  return (
    <Suspense fallback={<Loading />}>
      <BrowserRouter>
        <Routes>
          <Route path="sign-in" element={<SignIn />} />
          <Route path="sign-up" element={<SignUp />} />
          <Route path="verify/:uname" element={<Verify />} />
          <Route path="/" element={<RequireAuth> <PageContainer /> </RequireAuth>}>
            <Route index element={<Home />} />
            <Route path='profile' element={<Profile />} />
            <Route path='electronic-forms'>
              <Route index element={<EFList />} />
              <Route path="create" element={<EFCreate />} />
              <Route path=":id" element={<EFEdit />} />
              <Route path='open'>
                <Route path=":form_id" element={<EFOpen />} />
                <Route path="create/:form_id" element={<EFForm />} />
                <Route path="update/:record_id" element={<EFFormUpdate />} />
              </Route>
              <Route path='review'>
                <Route index element={<EFReview />} />
                <Route path=":form_id" element={<EFReview />} />
                <Route path="update/:record_id" element={<EFFormReview />} />
              </Route>
            </Route>
          </Route>
          <Route path="*" element={<main style={{ padding: "1rem" }}> <p>There's nothing here!</p> </main>} />
        </Routes>
      </BrowserRouter>
    </Suspense>
  );
}

export default App;
