import { Config } from "./config";

const FormResource = {
    base: "/api/custom/dmv/form",
    get(id, callback) {
        Config.Connection()
            .get(`${this.base}/${id}`)
            .then(r => callback(r.data))
            .catch(e => callback(e.response?.data));
    },
    find(params, callback) {
        Config.Connection()
            .post(`${this.base}/find`, params)
            .then(r => callback(r.data))
            .catch(e => callback(e.response?.data));
    },
    create(data, callback) {
        Config.Connection()
            .post(this.base, data)
            .then(r => callback(r.data))
            .catch(e => callback(e.response?.data));
    },
    update(data, callback) {
        Config.Connection()
            .put(this.base, data)
            .then(r => callback(r.data))
            .catch(e => callback(e.response?.data));
    },
};

export default FormResource