import { useNavigate } from "react-router-dom";
/**
 * Format 
 * {
        type: "grid4", 
        title: "Available Forms", 
        more: "/electronic-forms", 
        list: [
            { title: "Vehicle Registration/Renewal", description: "a good description of the module", url: "/electronic-forms/form/1" },
            { title: "Vehicle Sales Notice", description: "a good description of the module", url: "/electronic-forms/form/1" },
            { title: "License Plates Request", description: "a good description of the module", url: "/electronic-forms/form/1" },
        ]
    }
 * 
 * @param {*} param0 
 * @returns 
 */
export const Grid4 = ({ title = "", more = "", list = [] }) => {
    var navigate = useNavigate();
    return (<div className="col-12">
        <div className="card shadow">
            <div className="card-header">
                <h6 className="text-primary">{title}</h6>
            </div>
            <div className="card-body text-center">
                <div className="row">
                    {list.map((v, i) => <div key={i} className="col-12 col-sm-12 col-md-6 col-xl-4 col-xxl-4 mb-2">
                        <div className="card link shadow" onClick={() => { navigate(v.url) }}>
                            <div className="card-body">
                                <h4 className="card-title">{v.title}</h4>
                                <p className="text-nowrap text-truncate card-text">{v.description}</p>
                            </div>
                        </div>
                    </div>)}
                </div>
                <button className="btn btn-primary btn-sm" type="button" onClick={() => { navigate(more) }}>more</button>
            </div>
        </div>
    </div>);
}