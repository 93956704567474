import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";

const Node = ({ data = {}, editable = false, pkey = null, root = null, sendUpdate = () => { }, setCallbacks = () => { }, setShowCM = () => { } }) => {
    // const [data, setData] = useState(data);
    const [show, setShow] = useState(false);

    useEffect(() => {
        if (!data.key) {
            data.key = Math.floor(Math.random() * Date.now());
            sendUpdate();
        }
        // eslint-disable-next-line
    }, []);

    const getParent = (pkey, node) => {
        //if parent key is null, the return root
        if (pkey === node.key) return node;
        if (node.children) {
            for (const child of node.children) {
                let n = getParent(pkey, child);
                if (n) return n
            }
        }
        return null;
    }

    const selfDelete = () => {
        let p = getParent(pkey, root);
        if (p.key === data.key) return;
        for (let i = 0; i < p.children.length; i++) {
            if (data.key === p.children[i].key) {
                p.children.splice(i, 1);
                console.log("deleted", data);
                sendUpdate();
                break;
            }
        }
    }

    const handleContentextMenu = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setCallbacks({
            "Edit": () => setShow(true),
            "Delete": () => selfDelete(),
        });
        setShowCM(true);
    }

    const onDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (!data.children) data.children = [];
        let nNode = JSON.parse(e.dataTransfer.getData("data"));
        nNode.key = Math.floor(Math.random() * Date.now());
        data.children.push(nNode);
        sendUpdate();
    }
    const onDragOver = (e) => { e.preventDefault(); e.stopPropagation(); }
    const parse = (data) => {
        switch (data.node) {
            case "p":
                return <p onContextMenu={handleContentextMenu} className={`${data.class} drop-field`}>{data.content}</p>;
            case "span":
                return <span onContextMenu={handleContentextMenu} className={`${data.class} drop-field`}>{data.content}</span>;
            case "strong":
                return <strong onContextMenu={handleContentextMenu} className={`${data.class} drop-field`}>{data.content}</strong>;
            case "div":
                return <div onContextMenu={handleContentextMenu} onClick={() => setShowCM(false)} className={`${data.class} drop-field`} onDragOver={onDragOver} onDrop={onDrop}>{data.children?.map((c, i) => <Node pkey={data.key} root={root} editable={editable} setShowCM={setShowCM} sendUpdate={sendUpdate} setCallbacks={setCallbacks} data={c} key={i} />)}</div>;
            case "input":
                return <input onContextMenu={handleContentextMenu} placeholder={data.placeholder} className={data.class} type={data.type} required={data.required} />;
            case "textarea":
                return <textarea onContextMenu={handleContentextMenu} className={data.class} required={data.required} />;
            case "select":
                return (<select onContextMenu={handleContentextMenu} className={data.class} >
                    {data.options.map((v, i) => <option key={i} value={v.value}>{v.title}</option>)}
                </select>);
            case "label":
                return <label onContextMenu={handleContentextMenu} className={`${data.class} drop-field`} htmlFor={data.for} onDragOver={onDragOver} onDrop={onDrop}>{data.children?.map((c, i) => <Node pkey={data.key} root={root} editable={editable} setShowCM={setShowCM} sendUpdate={sendUpdate} setCallbacks={setCallbacks} data={c} key={i} />)}</label>;
            default:
                return null;
        }
    };
    return (
        <>
            <NodeData data={data} show={show} sendUpdate={sendUpdate} setShow={setShow} />
            {parse(data)}
        </>);
}


const NodeData = ({ data, setData, show, sendUpdate, setShow }) => {
    const props = { ...data };
    //delete the node attribute
    delete props.node; 
    delete props.children;
    delete props.key;
    const onInput = (e) => {
        // e.preventDefault();
        const n = e.target.name;
        const v = e.target.value;
        switch (n) {
            case "required":
                data[n] = e.target.checked;
                break;
            default:
                data[n] = v;
        }
        sendUpdate();
    }
    const parse = (key) => {
        switch (key) {
            case "content":
                return (
                    <label key={key} className="form-label w-100">
                        <span>{key}</span>
                        <textarea onInput={onInput} className="form-control w-auto" value={data[key]} name={key} ></textarea>
                    </label>
                );
            case "required":
                return (
                    <label key={key} className="form-label w-100">
                        <span>{key}</span>
                        <input onChange={onInput} type={"checkbox"} checked={data[key]} name={key} />
                    </label>
                );
            // case "options":
            //     return (
            //         <label key={key} className="form-label w-100">
            //             <span>{key}</span>
            //             <textarea onInput={onInput} className="form-control w-auto" value={data[key]} name={key} ></textarea>
            //         </label>
            //     );
            default:
                return (
                    <label key={key} className="form-label w-100">
                        <span>{key}</span>
                        <input onInput={onInput} className="form-control w-auto" value={data[key]} name={key} />
                    </label>
                );
        }
    }
    return (
        <>
            <Modal size="sm" show={show} onHide={() => setShow(false)}>
                <Modal.Header closeButton>
                    <Modal.Title> Node Properties </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {Object.keys(props).map(k => parse(k))}
                </Modal.Body>
            </Modal>
        </>
    );
}

export default Node