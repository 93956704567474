import { useContext, useState } from "react";
import { createContext } from "react";

const FlashAlertContext = createContext({});

const FlashAlertProvider = ({ children }) => {
    let [message, setMessage] = useState(null);
    let [type, setType] = useState(null);

    let Display = (message, type = "danger", callback = () => { }) => {
        setMessage(message);
        setType(type);
        callback(); 
    };
    let Clear = () => {
        setMessage(null);
        setType(null);
    };
    let value = { message, type, Display, Clear };
    return <FlashAlertContext.Provider value={value}>{children}</FlashAlertContext.Provider>
}

function useFlashAlert() {
    return useContext(FlashAlertContext);
}

export { FlashAlertProvider, useFlashAlert };