import React, { useEffect, useState } from "react";
import { Button, } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { FormRecordResource, FormResource } from "../../Resources";
import { TitledContent } from "../layout/TitledContent"
import { Alert } from "../utils/alert";

export const EFReview = () => {
    const [records, setRecords] = useState([]);
    const [form, setForm] = useState({});
    const [alerts, setAlerts] = useState(null);
    const navigate = useNavigate();
    const form_id = useParams()["form_id"];
    useEffect(() => {
        if (form_id) {
            FormResource.get(form_id, (r) => {
                if (r.status) return setForm(r.body);
                setAlerts(r.body);
            });
            FormRecordResource.find({ form_id: form_id }, (r) => {
                if (r.status) return setRecords(r.body);
                setAlerts(r.body);
            });
        } else {
            setForm({ title: "All Submitted Forms" });
            FormRecordResource.find({}, (r) => {
                if (r.status) return setRecords(r.body);
                setAlerts(r.body);
            });
        }
    }, [form_id]);

    const actions = () => {
        let btns = [];
        if (form_id) {
            btns.push(<Button key="1" onClick={() => navigate(`/electronic-forms/open/create/${form_id}`)} className="me-2" variant="primary" >
                create New Record
            </Button>);
        }
        btns.push(<Button key="back" onClick={() => navigate(`/electronic-forms`)} variant="secondary">Back</Button>);;
        return btns;
    }

    return (
        <TitledContent title={form.title || ""} actions={actions()}>
            <div className="card shadow">
                <div className="card-header py-3">
                    <p className="text-primary m-0 fw-bold">All Records</p>
                </div>
                <div className="card-body">
                    <Alert errors={alerts} tag="error" />
                    <div className="table-responsive">
                        <table className="table table-striped table-hover table-sm">
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Form</th>
                                    <th>Status</th>
                                    <th>Submitted</th>
                                    <th>Updated</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {records.map((r, i) => <tr key={i}>
                                    <td>{r.profile.fname} {r.profile.lname}</td>
                                    <td>{r.form.title}</td>
                                    <td>{r.status}</td>
                                    <td>{(new Date(r.created_at)).toDateString()}</td>
                                    <td>{(new Date(r.updated_at)).toDateString()}</td>
                                    <td>
                                        <div role="group" className="btn-group btn-group-sm">
                                            <button onClick={() => navigate(`/electronic-forms/review/update/${r.id}`)} className="btn btn-secondary" type="button">view</button>
                                            {/* <button onClick={() => navigate(`/electronic-forms`)} className="btn btn-danger" type="button">delete</button> */}
                                        </div>
                                    </td>
                                </tr>)}

                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </TitledContent>);
}