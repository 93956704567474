import { Config } from "./config";

const ProfileResource = {
    base: "/api/profile",
    myProfile(callback) {
        Config.Connection()
            .get(this.base)
            .then(resp => callback(resp.data))
            .catch(err => callback(err.response));
    },
    get(id, callback) {
        Config.Connection()
            .get(`${this.base}/${id}`)
            .then(resp => callback(resp.data))
            .catch(err => callback(err.response));
    },
    update(data, callback) {
        Config.Connection()
            .put(`${this.base}`, data)
            .then(resp => {
                callback(resp.data);
            })
            .catch(err => callback(err.response?.data));
    }
};
export default ProfileResource