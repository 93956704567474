const Loading = () => {
    return (<div className="d-flex justify-content-center align-items-center w-100 h-100">
        <div className="card shadow-lg o-hidden border-0 my-5 loading">
            <div className="card-body p-2 text-center">
                <h4 className="text-dark">Loading <i className="fas fa-spinner fa-spin"></i></h4>
            </div>
        </div>
    </div>);
}

export default Loading