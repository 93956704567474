import React from 'react';
import * as ReactDOMClient from 'react-dom/client';
import './index.css';
import './css/bootstrap.min.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { FlashAlertProvider } from './contexts/FlashAlertContext';
import { AuthProvider } from './contexts/AuthContext';
import { CoyProvider } from './contexts/CoyContext';

const container = document.getElementById('root');
const root = ReactDOMClient.createRoot(container);
root.render(
  <CoyProvider>
    <FlashAlertProvider>
      <AuthProvider>
        <React.StrictMode>
          <App />
        </React.StrictMode>
      </AuthProvider>
    </FlashAlertProvider>
  </CoyProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
