import React, { useState } from "react";
import { Button, } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { FormResource } from "../../Resources";
import { TitledContent } from "../layout/TitledContent"
import { Alert } from "../utils/alert";
import { DroppableElements, PreviewRoot, RootNode } from "./builder/index";

export const EFCreate = () => {
    const [elems, setElems] = useState({ node: "div", class: "" });
    const [inputs, setInputs] = useState({});
    const [alerts, setAlerts] = useState(null);
    const navigate = useNavigate();
    const update = () => {
        setAlerts(null);
        setElems({ ...elems });
        parser({ ...elems });
        setInputs(vals => ({ ...vals, ...{ data: elems } }));
    }
    const parser = (e, tracker = []) => {
        if (e.node === "input") {
            if (e.name === "") {
                setAlerts(vals => ({ ...vals, ...{ "name_error": `all inputs must have a name` } }));
            } else if (tracker.includes(e.name)) {
                if (e.class.search("blink-me") < 0) {
                    e.class += " blink-me";
                    setAlerts(vals => ({ ...vals, ...{ "name_error": `all ${e.type} must have unique names` } }));
                }
            }
            else tracker.push(e.name);
        }

        if (e.children) {
            for (let i = 0; i < e.children.length; i++) {
                parser(e.children[i], tracker);
            }
        }
    }

    const save = () => {
        setAlerts(null);
        parser(inputs.data);
        if (!alerts) {
            FormResource.create(inputs, (r) => {
                if (r?.status) {
                    return navigate(`/electronic-forms/${r.body.id}`);
                }
                setAlerts(r?.body);
            });
        }
    }


    const handleChange = (e) => {
        setAlerts(null);
        const name = e.target.name;
        const value = e.target.value;
        switch (name) {
            case "document_required":
                setInputs(values => ({ ...values, [name]: e.target.checked }));
                break;

            default:
                setInputs(values => ({ ...values, [name]: value }));
                break;
        }
    }

    const actions = () => {
        return [
            <Button key="1" onClick={save} variant="success" >
                <i className="fas fa-file-alt fa-sm text-white"></i> Save
            </Button>];
    }

    return (<div className="row">
        <div className="col-12 d-none d-md-none d-lg-block">
            <TitledContent title="New Electronic Forms" actions={actions()}>
                <div className="row">
                    <div className="col-12 mb-2">
                        <div className="card shadow">
                            <div className="card-header py-3">
                                <p className="text-primary m-0 fw-bold">Form Information</p>
                            </div>
                            <div className="card-body">
                                <Alert alerts={alerts} tag="message" type="info" />
                                <Alert alerts={alerts} tag="error" />
                                <div className="row">
                                    <div className="col">
                                        <label className="col-form-label w-100">
                                            Title
                                            <input onChange={handleChange} value={inputs.title || ""} type="text" className="form-control" placeholder="Enter title..." name="title" />
                                        </label>
                                        <Alert alerts={alerts} tag="title_error" />
                                    </div>
                                    <div className="col-12">
                                        <label className="col-form-label w-100">
                                            Description
                                            <textarea onChange={handleChange} value={inputs.description || ""} className="form-control" placeholder="Enter description..." name="description"></textarea>
                                        </label>
                                        <Alert alerts={alerts} tag="description_error" />
                                    </div>
                                    <div className="col">
                                        <div className="form-check">
                                            <input onChange={handleChange} checked={inputs.document_required || false} type="checkbox" className="form-check-input" id="document_required" name="document_required" />
                                            <label className="form-check-label" htmlFor="document_required">Documents Required</label>
                                        </div>
                                        <Alert alerts={alerts} tag="document_required_error" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-xxl-3">
                        <div className="card shadow">
                            <div className="card-header py-3">
                                <p className="text-primary m-0 fw-bold">Components</p>
                            </div>
                            <div className="card-body">
                                <DroppableElements />
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card shadow">
                            <div className="card-header py-3">
                                <p className="text-primary m-0 fw-bold">Form Canvas</p>
                            </div>
                            <div className="card-body" >
                                <Alert alerts={alerts} tag="name_error" />
                                <RootNode editable={true} root={elems} update={update} />
                                <Button onClick={save} variant="primary" size="sm" className="float-end">Save</Button>
                            </div>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="card shadow">
                            <div className="card-header py-3">
                                <p className="text-primary m-0 fw-bold">Preview</p>
                            </div>
                            <div className="card-body" >
                                <PreviewRoot root={elems} />
                            </div>
                        </div>
                    </div>
                </div>
            </TitledContent>
        </div>
        <div className="col-12 d-lg-none">
            <div className="card text-center">
                <div className="card-body">
                    <p className="card-text">This page can only be used on a wider display</p>
                    <button className="btn btn-danger btn-sm" type="button">
                        <i className="fas fa-arrow-left"></i> back
                    </button>
                </div>
            </div>
        </div>
    </div>);
}