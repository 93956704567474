import React, { useEffect, useState } from "react";
import { WidgetResource } from "../../Resources";
import { TitledContent } from "../layout/TitledContent"
import { Alert } from "../utils/alert";
import { Grid4 } from "./widgets/grid4";

export const Home = () => {
    const [widgets, setWidgets] = useState([]);
    const [errors, setErrors] = useState();
    useEffect(() => {
        setErrors(null);
        //get widgets
        WidgetResource.get(r => {
            if (r.status) return setWidgets(r.body);
            setErrors(r.body || { error: "unable to load widgets" });
        });
    }, []);

    const renderWidget = (w) => {
        switch (w.type) {
            case "grid4":
                return <Grid4 key={Math.floor(Math.random() * Date.now())} title={w.title} more={w.more} list={w.list} />
            default:
                break;
        }
        return null;
    }

    return (<TitledContent title="Dashboard">
        <div className="row">
            <Alert errors={errors} tag="error" />
            {widgets.map(w => renderWidget(w))}
        </div>
    </TitledContent>);
}