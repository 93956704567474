const DroppableElements = () => {
    const elems = [
        { title: "Row", data: { node: "div", class: "row" } },
        { title: "Column", data: { node: "div", class: "col" } },
        { title: "Paragraph", data: { node: "p", class: "", content: "" } },
        { title: "Strong", data: { node: "strong", class: "", content: "" } },
        { title: "Span", data: { node: "span", class: "", content: "" } },
        { title: "Text Input", data: { node: "input", type: "text", required: false, pattern: ".*", name: "", placeholder: "", class: "form-control" } },
        { title: "Number Input", data: { node: "input", type: "number", required: false, pattern: ".*", name: "", placeholder: "", class: "form-control" } },
        { title: "Password Input", data: { node: "input", type: "password", required: false, pattern: ".*", name: "", placeholder: "", class: "form-control" } },
        { title: "Email Input", data: { node: "input", type: "email", required: false, pattern: ".*", name: "", placeholder: "", class: "form-control" } },
        { title: "Textarea", data: { node: "textarea", required: false, name: "", placeholder: "", class: "form-control" } },
        { title: "Form Label", data: { node: "label", for: "", class: "form-label" } },
    ];
    const OnDragStart = (e, data) => {
        e.dataTransfer.setData("data", JSON.stringify(data));
    }
    return (
        <ul className="list-unstyled">
            <li>
                {elems.map((el, i) => <div onDragStart={(e) => OnDragStart(e, el.data)} key={i} draggable><p>{el.title}</p></div>)}
            </li>
        </ul>);
}

export default DroppableElements


