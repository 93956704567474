import { createContext, useState, useEffect, useContext } from "react";
import { AuthResource, ProfileResource } from "../Resources";

const AuthContext = createContext({});

const AuthProvider = ({ children }) => {
    let [token, setToken] = useState(window.localStorage.token);
    let [profile, setProfile] = useState({});
    const [permission, setPermission] = useState()
    const permit = (type) => {
        switch (type) {
            case 1:
                setPermission("e")
                break;
            case 2:
                setPermission("u")
                break;
            default:
                break;
        }
    }

    const signin = (newUser, callback) => {
        return AuthResource.signin(newUser, (resp) => {
            if (resp?.status) {
                setToken(resp.token);
                setProfile(resp.body.profile);
                permit(resp.body.profile.type);
            }
            callback(resp);
        })
    }
    const signout = (callback) => {
        AuthResource.signout((resp) => {
            callback(resp);
        });
        //unset the stores params
        setToken(null);
        setProfile({});
    };
    //this only runs once
    useEffect(() => {
        if (!token || token === "null" || token === "undefined") return
        ProfileResource.myProfile((resp) => {
            if (resp?.status) {
                setProfile(resp.body);
                permit(resp.body.type);
            }
        });
    }, [token]);
    //this runs anytime token changes or profile
    useEffect(() => {
        if (!token || token === "null" || token === "undefined") {
            delete window.localStorage.token;
            return;
        }
        window.localStorage.token = token;
    }, [token]);
    const value = { token, permission, profile, signin, signout };
    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

const useAuth = () => useContext(AuthContext);
const useToken = () => useContext(AuthContext).token;
const useProfile = () => useContext(AuthContext).profile;
const usePermission = () => useContext(AuthContext).permission;

export { AuthProvider, useAuth, useToken, useProfile, usePermission };