import { useToken } from "../../contexts/AuthContext";
import { useLocation, Navigate, } from "react-router-dom";

export const RequireAuth = ({ children }) => {
    let token = useToken();
    let location = useLocation();
    if (!token || token === "null" || token === "undefined") {
        return <Navigate to="/sign-in" state={{ from: location }} />;
    }

    return children;
}