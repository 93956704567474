const PreviewRoot = ({ root = {}, values = {}, readOnly = false, onChange = () => { } }) => {
    return <PreviewNode data={root} values={values} readOnly={readOnly} onChange={onChange} />
}

const PreviewNode = ({ data = {}, values = {}, readOnly = false, onChange = () => { } }) => {
    const parse = (data) => {
        switch (data.node) {
            case "p":
                return <p className={data.class}>{data.content}</p>;
            case "span":
                return <span className={data.class}>{data.content}</span>;
            case "strong":
                return <strong className={data.class}>{data.content}</strong>;
            case "div":
                return <div className={data.class}>{data.children?.map((c, i) => <PreviewNode key={i} data={c} values={values} readOnly={readOnly} onChange={onChange} />)}</div>;
            case "input":
                return <input onChange={onChange} value={values[data.name] || ""} readOnly={readOnly} className={data.class || ""} name={data.name || ""} placeholder={data.placeholder} type={data.type} required={data.required} pattern={data.pattern} />;
            case "textarea":
                return <textarea onChange={onChange} value={values[data.name] || ""} readOnly={readOnly} className={data.class || ""} name={data.name || ""} placeholder={data.placeholder} required={data.required} />;
            case "label":
                return <label className={data.class} htmlFor={data.for}>{data.children?.map((c, i) => <PreviewNode key={i} data={c} values={values} readOnly={readOnly} onChange={onChange} />)}</label>;
            default:
                return null;
        }
    };
    return parse(data);
}
export default PreviewRoot