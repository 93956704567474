import React, { useEffect, useState } from "react";
import { Button, } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { FormRecordResource } from "../../Resources";
import { TitledContent } from "../layout/TitledContent"
import { Alert } from "../utils/alert";
import { Hide } from "../utils/hide";
import { PreviewRoot } from "./builder/index";

export const EFFormReview = () => {
    const [elems, setElems] = useState({ node: "div", class: "" });
    const [record, setRecord] = useState({});
    const [inputs, setInputs] = useState({});
    const [status, setStatus] = useState([]);
    const [alerts, setAlerts] = useState();
    const navigate = useNavigate();
    const record_id = useParams()["record_id"];

    useEffect(() => {
        FormRecordResource.status((r) => {
            if (r.status) return setStatus(r.body);
            setAlerts(r.body);
        });
    }, []);
    useEffect(() => {
        FormRecordResource.get(record_id, (r) => {
            if (r.status) {
                setElems(r.body.form.maped_data);
                setRecord(r.body);
                const entries = r.body?.entries || [];
                setInputs(entries.reduce((p, c) => ({ ...p, [c.name]: c.value }), {
                    status: r.body.status,
                    comment: r.body.comment,
                }));
                return;
            }
            setAlerts(r.body);
        });
    }, [record_id]);

    const onChange = (e) => {
        setAlerts(null);
        const name = e.target.name;
        const value = e.target.value;
        switch (name) {
            case "document_required":
                setInputs(values => ({ ...values, [name]: e.target.checked }));
                break;

            default:
                setInputs(values => ({ ...values, [name]: value }));
                break;
        }
    }

    const onSubmit = (e) => {
        e.preventDefault();
        setAlerts(null);
        if (!e.target.checkValidity()) return
        const formData = new FormData(e.target);
        for (const data of formData) {
            console.log(data[0], ":", data[1]);
        }
        formData.set("id", record.id);
        FormRecordResource.update(formData, (r) => {
            if (r.status) return setAlerts({ updated: "your review has been submitted" });
            setAlerts(r.body);
        });
    }

    const actions = () => {
        return [
            <Button onClick={() => navigate(`/electronic-forms/review/${record.form_id}`)} key="back" variant="secondary">Back</Button>
        ];
    }

    return (
        <TitledContent title="Electronic Forms" actions={actions()}>
            <div className="row">
                <form onSubmit={onSubmit}>
                    <div className="col-12 mb-2">
                        <div className="card shadow">
                            <div className="card-header py-3">
                                <p className="text-primary m-0 fw-bold">Form Information</p>
                            </div>
                            <div className="card-body">
                                <h6>Submitted By: {record.profile?.fname || ""} {record.profile?.mname || ""} {record.profile?.lname || ""}</h6>
                                <h6>Last Reviewed By: {record.reviewd_by?.fname || ""} {record.reviewd_by?.mname || ""} {record.reviewd_by?.lname || ""}</h6>
                                <h6>Submit Date: {(new Date(record?.created_at)).toDateString()}</h6>
                                <h6>Review Date: {(new Date(record?.created_at)).toDateString()}</h6>
                                <div className="col-12 col-sm-6 col-md-5 col-lg-4 col-xl-3 col-xxl-2">
                                    <label className="col-form-label w-100">Status:
                                        <select onChange={onChange} value={inputs.status || ""} name="status" className="form-select">
                                            {status.map((v, i) => <option key={i} value={v}>{v}</option>)}
                                        </select>
                                    </label>
                                </div>
                                <div className="col-12">
                                    <label className="col-form-label w-100">Comment:
                                        <textarea onChange={onChange} value={inputs.comment || ""} name="comment" className="form-control"></textarea>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 mb-2">
                        <div className="card shadow">
                            <div className="card-header py-3">
                                <p className="text-primary m-0 fw-bold">{record.form?.title || ""}</p>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-12">
                                        <Alert alerts={alerts} tag="error" />
                                        <Alert alerts={alerts} tag="updated" type="success" />
                                    </div>
                                    <div className="col-12">
                                        <PreviewRoot root={elems} values={inputs} onChange={onChange} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Hide state={!record.form?.document_required} >
                        <div className="col-12 mb-2">
                            <div className="card shadow mb-2">
                                <div className="card-header py-3">
                                    <p className="text-primary m-0 fw-bold">Documents</p>
                                </div>
                                <div className="card-body">
                                    <div className="text-center text-white w-100 p-3 bg-secondary mb-2"><i className="fas fa-plus fs-3"></i>
                                        <p>drag and drop files here or click to add</p>
                                    </div>
                                    <ul className="list-group">
                                        <li className="list-group-item"><span>File Name.pdf</span>
                                            <button className="btn btn-danger btn-sm float-end" type="button">
                                                <i className="fas fa-times"></i> remove
                                            </button>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </Hide>
                    <div className="col-12">
                        <Alert alerts={alerts} tag="error" />
                        <Alert alerts={alerts} tag="updated" type="success" />
                    </div>
                    <div className="col-12 my-3 text-end">
                        <button className="btn btn-primary" type="submit">
                            <i className="fas fa-file-alt fa-sm text-white"></i> Submit Review
                        </button>
                    </div>
                </form>
            </div>
        </TitledContent>);
}