import { createContext, useState, useEffect, useContext } from "react";
import { CoyResource } from "../Resources/Coy";

const CoyContext = createContext({});

const CoyProvider = ({ children }) => {
    let [coy, setCoy] = useState();
    const getCoy = () => {
        return CoyResource.info((resp) => {
            if (resp?.status) {
                setCoy(resp.body);
            }
        })
    }
    //this only runs once
    useEffect(() => {
        getCoy();
    }, []);
    const value = { coy };
    return <CoyContext.Provider value={value}>{children}</CoyContext.Provider>;
};

const useCoy = () => useContext(CoyContext).coy;

export { CoyProvider, useCoy };