import React, { useEffect, useState } from "react";
import { Button, } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { usePermission } from "../../contexts/AuthContext";
import { FormResource } from "../../Resources";
import { TitledContent } from "../layout/TitledContent"
import { Alert } from "../utils/alert";
import { Hide } from "../utils/hide";

export const EFList = () => {
    var navigate = useNavigate();
    const [list, setList] = useState([]);
    const [alerts, setAlerts] = useState();
    const permit = usePermission();

    useEffect(() => {
        FormResource.find({}, (r) => {
            if (r.status) {
                setList(r.body);
                return;
            }
            setAlerts(r.body);
        });
    }, []);
    const allow = () => permit !== "e";
    const actions = () => {
        if (allow()) return [];
        return [
            <Button onClick={() => navigate("/electronic-forms/create")} key="1" variant="primary">
                <i className="fas fa-file-alt fa-sm text-white"></i> New
            </Button>];
    }

    return (
        <TitledContent title="Electronic Forms" actions={actions()}>
            <div className="card shadow">
                <div className="card-header py-3">
                    <p className="text-primary m-0 fw-bold">List</p>
                </div>
                <div className="card-body">
                    <Alert errors={alerts} tag="error" />
                    <div className="row">
                        <div className="col-md-6 text-nowrap">
                            <div id="dataTable_length" className="dataTables_length" aria-controls="dataTable">
                                <label className="form-label">Show
                                    <select className="d-inline-block form-select form-select-sm">
                                        <option value="10">10</option>
                                        <option value="25">25</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                    </select></label></div>
                        </div>
                        <div className="col-md-6">
                            <div className="text-md-end dataTables_filter" id="dataTable_filter"><label className="form-label"><input type="search" className="form-control form-control-sm" aria-controls="dataTable" placeholder="Search" /></label></div>
                        </div>
                    </div>
                    <div className="table-responsive">
                        <table className="table table-striped table-hover table-sm">
                            <thead>
                                <tr>
                                    <th>Title</th>
                                    <th>Description</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {list.map((v, i) => <tr key={i}>
                                    <td>{v.title}</td>
                                    <td>{v.description}</td>
                                    <td className="text-end">
                                        <div role="group" className="btn-group btn-group-sm">
                                            <button onClick={() => navigate(`/electronic-forms/open/${v.id}`)} className="btn btn-primary" type="button">open</button>
                                            <Hide state={allow()}>
                                                <button onClick={() => navigate(`/electronic-forms/review/${v.id}`)} className="btn btn-secondary" type="button">review</button>
                                                <button onClick={() => navigate(`/electronic-forms/${v.id}`)} className="btn btn-info" type="button">edit</button>
                                                <button className="btn btn-danger" type="button">delete</button>
                                            </Hide>
                                        </div>
                                    </td>
                                </tr>)}

                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </TitledContent>);
}