import { Config } from "./config";

export const WidgetResource = {
    isAuthenticated: false,
    base: "/api/custom/dmv",
    get(callback) {
        Config.Connection()
            .get(`${this.base}/widget`)
            .then(r => callback(r.data))
            .catch(e => callback(e.response?.data));
    }
};
export default WidgetResource