import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import Node from "./node"
const RootNode = ({ root = {}, update = () => { } }) => {
    const [showCM, setShowCM] = useState(false);
    const [callbacks, setCallbacks] = useState({});
    const sendUpdate = () => update(root);
    const onClick = (e) => {
        setShowCM(false);
    }
    useEffect(() => {
        if (!root.key) {
            root.key = Math.floor(Math.random() * Date.now());
            update(root);
        }
        // eslint-disable-next-line
    }, []);
    return <>
        <ContextMenu showCM={showCM} setShowCM={setShowCM} callbacks={callbacks} />
        <Node data={root} pKey={root.key} root={root} onClick={onClick} sendUpdate={sendUpdate} setShowCM={setShowCM} setCallbacks={setCallbacks} />
    </>;
}

const ContextMenu = ({ showCM, setShowCM = () => { }, callbacks = {} }) => {
    const onClick = (k) => {
        setShowCM(false);
        callbacks[k]();
    }
    if (!showCM) return null;
    return (
        <Dropdown show={showCM} onSelect={onClick}>
            <Dropdown.Menu>
                {Object.keys(callbacks).map((k, i) => <Dropdown.Item key={i} eventKey={k}>{k}</Dropdown.Item>)}
            </Dropdown.Menu>
        </Dropdown>);
}

export default RootNode