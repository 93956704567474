import axios from "axios";
import { Config } from "./config";

const AuthResource = {
    isAuthenticated: false,
    base: `${Config.BaseUrl()}/api/auth`,
    signin(user, callback) {
        axios.post(`${this.base}/signin`, {
            "uname": user.userID,
            "pword": user.password
        }, { withCredentials: true })
            .then(resp => {
                this.isAuthenticated = true;
                callback(resp.data);
            })
            .catch(err => callback(err.response?.data));
    },
    signup(data, callback) {
        axios.post(`${this.base}/signup`, data)
            .then(resp => {
                callback(resp.data);
            })
            .catch(err => callback(err.response?.data));
    },
    verify(data, callback) {
        axios.post(`${this.base}/verify`, data)
            .then(resp => {
                callback(resp.data);
            })
            .catch(err => callback(err.response?.data));
    },
    signout(callback) {
        Config.Connection()
            .get(`${this.base}/signout`, {}, { withCredentials: true })
            .then(resp => {
                this.isAuthenticated = false;
                callback(resp.data);
            })
            .catch(err => callback(err.response?.data.body));
    },
};

export default AuthResource